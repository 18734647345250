<template>
  <v-container
    style="overflow: hidden;"
    fluid
    class="ma-0 pa-0  background "
  >
    <headerr class="_header" @destra="navigation = !navigation" :menu="true">
      Linee Guida
    </headerr>
    <div class="_container">
      <v-navigation-drawer
        class="navigatore"
        v-model="navigation"
        right
        hide-overlay
        temporary
        app
        style="border-bottom-left-radius: 15px !important"
      >
        <v-list dense nav>
          <v-list-item-group v-model="group" active-class="primary--text">
            <v-list-item class="py-1" v-for="item in navigatori" :key="item">
              <v-list-item-title class="fontNavigatore">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <div style="height: 40%; width: 100%" class="white elevation-8">
        <v-img contain max-height="100%" :src="immagine" />
      </div>
      <div class="bottoniScroll">
        <v-row justify="center" class="ma-0 pa-4 pt-10">
          <v-col v-for="item in listaSelezionati" :key="item.id" cols="3">
            <v-btn
              class="textWrapBtn"
              height="100"
              width="100%"
              @click="
                info = item;
                infoDialog = true;
              "
            >
              {{ item.name }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog max-width="700" v-model="infoDialog">
      <v-card height="70vh"  style="overflow: hidden">
        <v-card-title class="bianco primary titolo">
          {{ info.name }}
          <v-row
            style="max-width: 500px !important"
            justify="end"
            class="px-2 py-0 my-0"
          >
            <v-col class="my-0 py-0" cols="1">
              <v-icon color="white" class="my-0 py-0">
                mdi-format-font-size-decrease
              </v-icon>
            </v-col>
            <v-col class="my-0 pa-0" cols="8">
              <v-slider
                hide-details=""
                color="white"
                class="my-0 py-0"
                dense
                :max="25"
                :min="14"
                v-model="fontCard"
              ></v-slider>
            </v-col>
            <v-col class="my-0 py-0" cols="1">
              <v-icon color="white" class="my-0 py-0">
                mdi-format-font-size-increase
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text :style="stile" class=" testoDialog">
          {{ info.descrizione }}
        </v-card-text>
        <v-card-actions style="height: 50px" class="">
          <v-spacer />
          <v-btn elevation="0" color="error" text  @click="infoDialog = false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import laterale from "../../json/LineeGuida/laterale.json";
import litotomica from "../../json/LineeGuida/litotomica.json";
import lloydDavies from "../../json/LineeGuida/lloydDavies.json";
import prona from "../../json/LineeGuida/prona.json";
import sittingBeachChair from "../../json/LineeGuida/sittingBeachChair.json";
import supina from "../../json/LineeGuida/supina.json";
export default {
  data() {
    return {
      fontCard: 20,
      navigatori: [
        "Supina",
        "Litomica",
        "Sitting-Beach Chair",
        "Laterale",
        "Prona",
        "Lloyd Davies"
      ],
      infoDialog: false,
      info: {},
      navigation: false,
      group: 0,
      laterale,
      litotomica,
      lloydDavies,
      prona,
      sittingBeachChair,
      supina
    };
  },
  watch: {
    group() {
      this.navigation = false;
      if (this.group == undefined) this.group = 0;
    }
  },
  computed: {
    stile() {
      const dimensione = "font-size:" + this.fontCard + "px;";

      return dimensione;
    },
    listaSelezionati() {
      switch (this.group) {
        case 0:
          return this.supina;
        case 1:
          return this.litotomica;
        case 2:
          return this.sittingBeachChair;
        case 3:
          return this.laterale;
        case 4:
          return this.prona;
        case 5:
          return this.lloydDavies;
      }

      return [];
    },
    immagine() {
      switch (this.group) {
        case 0:
          return "/img/stadi/supina.jpg";
        case 1:
          return "/img/stadi/litotomica.jpg";
        case 2:
          return "/img/stadi/sittingBeachChair.jpg";
        case 3:
          return "/img/stadi/laterale.jpg";
        case 4:
          return "/img/stadi/prona.jpg";
        case 5:
          return "/img/stadi/lloydDavies.jpg";
      }
      return "";
    }
  },
  created() {
    this.group = 0;
  }
};
</script>

<style>
._header {
  top: 0px;

  width: 100vw;
  position: fixed !important;
  z-index: 3;
}
._content {
  margin-top: 50px !important;
  width: 100%;
}
.altezza {
  height: 100%;
  width: 100%;
  padding-bottom: 70px;
}
.navigatore {
  height: auto !important;
}
.immagineDiv {
  height: 40vh;
}
.fontNavigatore {
  font-size: 20px !important;
  height: 40px;
  display: flex;
  align-items: center;
}
.titolo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
}

.bottoniScroll {
  overflow-y: auto;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.192);
}
.testoDialog {
  overflow: auto;
  height: 700px;
  letter-spacing: 1px;
  line-height: 150%;
  height: calc(100% - 105px);
  
}
</style>
