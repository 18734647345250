<template>
  <v-footer app color="primary" height="50px" class="justify-center">
    <div style="display: flex; gap: 1rem">
      <a
        class="link"
        @click="
          visitaPagina(
            'https://firebasestorage.googleapis.com/v0/b/tobin-gpsmed-playground.appspot.com/o/Q313LE22004-GpsMed-Licenza_EULA.pdf?alt=media&token=134968f7-8d8f-4aca-985a-1987df39468c'
          )
        "
      >
        Privacy Policy
      </a>
      <a
        class="link"
        @click="
          visitaPagina(
            'https://firebasestorage.googleapis.com/v0/b/tobin-gpsmed-playground.appspot.com/o/Q313PY22005-GpsMed-Privacy_Policy.pdf?alt=media&token=527a6669-51df-4350-b052-a1bc1f746fa5'
          )
        "
      >
        Termini e condizioni d'uso
      </a>
      <a class="link" @click="visitaPagina('https://tobin.cloud')">TOBIN</a>
      <a class="link" @click="visitaPagina('https://altamed.net')">ALTAMED</a>
    </div>
  </v-footer>
</template>

<script>
export default {
  methods: {
    visitaPagina(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped>
.link {
  color: #fff !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>
