<template>
  <v-dialog persistent max-width="400" v-model="visible">
    <v-card>
      <v-card-title>Inserisci le tue credenziali</v-card-title>
      <v-card-text class="px-4">
        <v-select
          label="Operatore"
          outlined
          hide-details="auto"
          v-model="operatore"
          :items="operatori"
        />
        <v-text-field
          class="mt-3"
          label="PIN"
          type="number"
          outlined
          hide-details="auto"
          v-model="pin"
          hide-spin-buttons
          clearable
        />
      </v-card-text>
      <v-card-actions class="pb-4 d-flex justify-end">
        <v-btn elevation="0" text @click="close()">Annulla</v-btn>
        <v-btn
          elevation="0"
          width="50%"
          :color="isDeleteDialog ? 'error' : 'primary'"
          @click="emitCredentials()"
          :disabled="!operatore || !pin"
          :loading="loading"
        >
          {{ isDeleteDialog ? "Elimina" : "Ok" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      operatore: null,
      loading: false,
      pin: "",
      callback: null,
      args: null
    };
  },
  props: {
    admins: {
      type: Boolean,
      default: false
    },
    isDeleteDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    show(callback, ...args) {
      this.visible = true;
      this.callback = callback;
      this.args = args;
    },
    close() {
      this.visible = false;
      this.operatore = null;
      this.pin = "";
      this.callback = null;
      this.args = null;
    },
    async emitCredentials() {
      this.loading = true;
      //execute callback if exists
      if (this.callback)
        await this.callback(this.operatore, this.pin, ...this.args);
      this.$emit("credentials", {
        operatore: this.operatore,
        pin: this.pin
      });
      if (!this.callback) this.close();
      this.loading = false;
    }
  },
  computed: {
    operatori() {
      var operatori = [];
      if (this.admins)
        operatori = this.$store.getters["operatori/operatoriAdmin"];
      else operatori = this.$store.getters["operatori/operatori"];

      return operatori.map((o) => {
        return {
          text: `${o.nome} ${o.cognome} ${o.badge ? `(${o.badge})` : ""}`,
          value: o.id
        };
      });
    }
  }
};
</script>
<style></style>
