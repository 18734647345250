import { firebaseStore, functions } from "@/plugins/firebase";

const state = {
    pazienti: [],
};

const getters = {
    pazienti: (state) => state.pazienti
};

const actions = {
    async recuperaPazienti({ commit, rootGetters }) {
        const idStruttura = rootGetters["struttura/id"];

        firebaseStore.collection("pazienti")
        .where("struttura", "==", idStruttura)
        .where("deleted", "==", false)
        .onSnapshot((snapshot) => {
            const pazienti = snapshot.docs.map((paziente) => ({
                ...paziente.data(),
                id: paziente.id,
            }));
            commit("aggiornaPazienti", pazienti);
        });

        const pazienti = await firebaseStore
            .collection("pazienti")
            .where("struttura", "==", idStruttura)
            .where("deleted", "==", false)
            .get();
        commit(
            "aggiornaPazienti",
            pazienti.docs.map((paziente) => ({...paziente.data(), id: paziente.id}))
        );
    },

    async aggiungiPaziente({ commit, dispatch }, data) {
        const modificaPaziente = functions.httpsCallable("modificaPaziente");
        const payload = {
            action: "create",
            payload: data.payload,
            operatore: data.operatore,
            pin: data.pin,
            id: data.id,
        };
        await modificaPaziente(payload);
        await dispatch("recuperaPazienti");
    },

    async modificaPaziente({ commit, dispatch }, data) {
        const modificaPaziente = functions.httpsCallable("modificaPaziente");
        const payload = {
            action: "update",
            payload: data.payload,
            operatore: data.operatore,
            pin: data.pin,
            id: data.id,
        };
        await modificaPaziente(payload);
        await dispatch("recuperaPazienti");
    },

    async eliminaPaziente({ commit, dispatch }, data) {
        const modificaPaziente = functions.httpsCallable("modificaPaziente");
        const payload = {
            action: "delete",
            operatore: data.operatore,
            pin: data.pin,
            id: data.id,
        };
        await modificaPaziente(payload);
        await dispatch("recuperaPazienti");
    }
};

const mutations = {
    aggiornaPazienti(state, payload) {
        state.pazienti = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
