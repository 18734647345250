export function getDefaultStruttura() {
  return {
    struttura: "",
    email: "",
    indirizzo: "",
    telefono: "",
    note: "",
    eulaAccettata: true,
    interventi: [],
    preferiti: [],
    dataRegistrazione: null,
    dataUltimaModifica: null,
    industria_40: false
  };
}
