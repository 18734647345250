import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import struttura from "@/store/modules/struttura";
import categorie from "@/store/modules/categorie";
import aggiornamento from "@/store/modules/aggiornamento";
import liste from "@/store/modules/liste";
import operatori from "@/store/modules/operatori";
import pazienti from "@/store/modules/pazienti";
Vue.use(Vuex);

const persistent = createPersistedState({
  paths: ["categorie"]
});

export default new Vuex.Store({
  modules: {
    struttura,
    categorie,
    aggiornamento,
    liste,
    operatori,
    pazienti
  },
  plugins: [persistent]
});
