<template>
  <v-container fluid class="ma-0 pa-0 d-flex flex-column fill-height white">
    <headerr class="_header">Formazione</headerr>
    <div class="_content">
    <v-row class="ma-0 pa-0">
      <v-col v-for="(item, index) in lista" :key="index" :cols="item.cols">
        <v-btn
          color="primary"
          elevation="10"
          height="200px"
          rounded
          @click="$router.push(item.path)"
          width="100%"
          >{{ item.text }}</v-btn
        >
      </v-col>
    </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lista: [
        {
          text: "Linee guida",
          path: "/lineeGuida",
          cols: 12
        },
        {
          text: "Cenni anatomici",
          path: "/cenniAnatomici",
          cols: 6
        },
        {
          text: "Raccomandazioni",
          path: "/raccomandazioni",
          cols: 6
        },
        {
          text: "Procedura/Protocollo",
          path: "/procedura",
          cols: 6
        },
        {
          text: "Bibliografia",
          path: "/bibliografia",
          cols: 6
        }
      ]
    };
  }
};
</script>
<style scoped>
._header{
  width: 100vw;
  position: fixed !important;
  z-index: 3;
}
._content{
margin-top: 50px;
}
</style>