<template>
  <v-container fluid class="fill-height ">
    <headerr class="_header">Procedura/Protocollo</headerr>
    <div class=" d-flex align-center mx-0 mb-0 altezza  pa-0 ">
      
      <v-img v-if="true" max-height="100%" contain :src="Immagine"></v-img>
    </div>
  </v-container>
</template>

<script>
import {storage} from "../../plugins/firebase"
export default {
  data(){
    return{
      Immagine: "/img/algoritmo.jpg"
    }
  },
  methods: {
    
  },
  computed:{
    uid(){return this.$store.getters["user/uid"]},
    struttura(){
      return this.$store.getters["struttura/struttura"]
    },
    
  },
  created(){
    if (this.struttura.procedura != "default"){
      storage.ref("strutture/" + this.uid).child(this.struttura.procedura).getDownloadURL().then(url=>{this.Immagine =  url; console.log("scaricata")})
      
    }
  }
}
</script>

<style scoped>
._header{
  top: 0px;
  
  width: 100vw;
  position: fixed !important;
  z-index: 3;
}

.altezza{
  height:  max(100vh - 100px, 400px); 
  width: 100% ;
  margin-top: 50px;
  position: absolute;
}
</style>