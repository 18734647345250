import { aggiornaApplicazione } from "@/functions/aggiornaApplicazione.js";
import { convertiTimestamp } from "@/functions/utils";

const state = {
  interventi: [],
  configurazioni: {},
  accessori: {},
  dataUltimaModifica: null
};

const getters = {
  interventi: (state) => state.interventi,
  configurazione: (state) => (id) => state.configurazioni[id],
  listaConfigurazioni: (state) => Object.keys(state.configurazioni),
  accessorio: (state) => (id) => state.accessori[id],
  listaAccessori: (state) => Object.keys(state.accessori),
  listaAccessoriCompleti: (state) => {
    return Object.keys(state.accessori).map((idAccessorio) => {
      return {
        idFirebase: idAccessorio,
        ...state.accessori[idAccessorio]
      };
    });
  },
  formattaIntervento: (state) => (id) => {
    const intervento = state.interventi.find((intervento) => intervento.id == id);
    if (intervento === undefined) return null;
    else return intervento.nome.toUpperCase();
  },
  intervento: (state) => (id) => state.interventi.find((intervento) => intervento.id == id),
  accessoriConfigurazione: (state) => (idConfigurazione) => {
    const configurazione = state.configurazioni[idConfigurazione];
    var accessori = [];
    configurazione.configurazioneScenaAccessori.forEach((configurazioneScenaAccessorio) => {
      if (!accessori.find((accessorio) => accessorio.id == configurazioneScenaAccessorio.originalName))
        accessori.push(state.accessori[configurazioneScenaAccessorio.originalName]);
    });
    configurazione.configurazioneScenaPosizionamento.forEach((configurazioneScenaPosizionamento) => {
      if (!accessori.find((accessorio) => accessorio.id == configurazioneScenaPosizionamento.originalName))
        accessori.push(state.accessori[configurazioneScenaPosizionamento.originalName]);
    });
    return accessori;
  },

  /* ottieniDatiIntervento: (state) => (idIntervento) => {
    const { configurazione, nome } = state.interventi.find(({ id }) => id == idIntervento);
    const { warnings } = state.configurazioni[configurazione];
console.log(state.configurazioni[configurazione]);
    return {
      nome,
      accessori: ,
      warnings: warnings.map((warning) => {
        return {
          warning,
          selezionato: false
        };
      })
    };
  } */
};

const mutations = {
  aggiornaInterventi(state, payload) {
    state.interventi = payload.sort((a, b) => a.nome.localeCompare(b.nome));
  },
  aggiornaConfigurazioni(state, payload) {
    state.configurazioni = payload;
  },
  aggiornaAccessori(state, payload) {
    state.accessori = payload;
  },
  aggiornaDataUltimaModifica(state, payload) {
    state.dataUltimaModifica = payload;
  }
};
const actions = {
  async recuperaDati({ state, commit, rootGetters }) {
    const { dataUltimaModifica } = rootGetters["struttura/struttura"];
    const dataUltimaModificaStruttura = convertiTimestamp(dataUltimaModifica);
    let dataUltimaModificaSalvata = null;
    if (state.dataUltimaModifica != null) {
      dataUltimaModificaSalvata = convertiTimestamp(state.dataUltimaModifica);
    }

    /**
     * Il modulo "categorie.js" è persistente.
     * Controllo che la dataUltimaModifica salvata in questo modulo non sia minore
     * della dataUltimaModifica contenuta all'interno del modulo "struttura.js"
     * (che si inzializza ogniqualvolta un utente si autentica).
     * Nel caso fosse minore aggiorno l'applicazione.
     */
    // if(state.dataUltimaModifica === null || dataUltimaModificaStruttura > dataUltimaModificaSalvata) {
    await aggiornaApplicazione();
    commit("aggiornaDataUltimaModifica", dataUltimaModifica);
    // }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
