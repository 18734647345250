import { firebaseStore, functions } from "@/plugins/firebase";

const state = {
  operatori: []
};

const getters = {
  operatori(state) {
    return state.operatori;
  },
  operatoriAdmin(state) {
    return state.operatori.filter((operatore) => {
      return operatore.admin;
    });
  }
};

const mutations = {
  setOperatori(state, payload) {
    state.operatori = payload;
  }
};

const actions = {
  async recuperaOperatori({ commit, rootGetters }) {
    const idStruttura = rootGetters["struttura/id"];
    const operatori = await firebaseStore
      .collection("operatori")
      .where("eliminato", "==", false)
      .where("struttura", "==", idStruttura)
      .get();
    commit(
      "setOperatori",
      operatori.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  },

  async aggiungiOperatore({ commit, dispatch }, data) {
    const modificaOperatore = functions.httpsCallable("modificaOperatore");
    const payload = {
      action: "create",
      payload: data.payload,
      operatore: data.credentials.operatore,
      pin: data.credentials.pin
    };
    await modificaOperatore(payload);
    await dispatch("recuperaOperatori");
  },
  async modificaOperatore({ commit, dispatch }, data) {
    const modificaOperatore = functions.httpsCallable("modificaOperatore");
    const payload = {
      action: "update",
      payload: data.payload,
      operatore: data.credentials.operatore,
      pin: data.credentials.pin,
      id: data.id
    };
    await modificaOperatore(payload);
    await dispatch("recuperaOperatori");
  },

  async eliminaOperatore({ commit, dispatch }, data) {
    const modificaOperatore = functions.httpsCallable("modificaOperatore");
    const payload = {
      action: "delete",
      id: data.id,
      operatore: data.credentials.operatore,
      pin: data.credentials.pin
    };
    await modificaOperatore(payload);
    await dispatch("recuperaOperatori");
  },

  async changePin({ commit, dispatch }, data) {
    console.log(data);
    const modificaOperatore = functions.httpsCallable("modificaOperatore");
    const payload = {
      action: "changeMyPin",
      operatore: data.credentials.operatore,
      pin: data.credentials.pin,
      payload: {
        pin: data.pin
      }
    };
    // console.log(payload);
    await modificaOperatore(payload);
    await dispatch("recuperaOperatori");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
