import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

import store from "@/store";
import router from "@/router";

firebase.initializeApp({
  apiKey: "AIzaSyAB9SdmPyzdDtRIvAeVUL0k_CpwVKyLYmE",
  authDomain: "tobin-gpsmed-playground.firebaseapp.com",
  projectId: "tobin-gpsmed-playground",
  storageBucket: "tobin-gpsmed-playground.appspot.com",
  messagingSenderId: "294961755658",
  appId: "1:294961755658:web:17086e290ce0a4ecee91f3"
});

firebase.auth().onAuthStateChanged(async (user) => {
  if (!user) {
    store.dispatch("struttura/disconnetti");
    store.dispatch("liste/disconnetti");
    router.push("/");
    return;
  }

  const idStruttura = user.uid;
  store.dispatch("liste/recuperaDati");
  const struttura = await store.dispatch("struttura/recuperaDati", idStruttura);
  router.push("/home");
  await store.dispatch("categorie/recuperaDati");
  if (struttura.industria_40) {
    await store.dispatch("operatori/recuperaOperatori");
  }
});

export const firebaseAuth = firebase.auth();
export const auth = firebase.auth();
export const database = firebase.firestore();
export const firebaseStore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.app().functions("europe-west1");
