import Vue from "vue";

const state = {
  controllo: false,
  aggiornamento: false,
  descrizione: "",
  stato: 0,
  errore: null
};

const getters = {
  controllo: (state) => state.controllo,
  stato: (state) => state.stato,
  aggiornamento: (state) => state.aggiornamento,
  descrizione: (state) => state.descrizione,
  errore: (state) => state.errore
};

const mutations = {
  aggiornaControllo(state, paylaod) {
    Vue.set(state, "errore", null);
    Vue.set(state, "controllo", paylaod);
  },
  aggiornaAggiornamento(state, paylaod) {
    Vue.set(state, "aggiornamento", paylaod);
  },
  aggiornaDescrizione(state, paylaod) {
    Vue.set(state, "descrizione", paylaod);
  },
  aggiornaStato(state, paylaod) {
    Vue.set(state, "stato", paylaod);
  },
  inizializza(state) {
    Vue.set(state, "controllo", false);
    Vue.set(state, "aggiornamento", false);
    Vue.set(state, "descrizione", "");
    Vue.set(state, "stato", 0);
    Vue.set(state, "errore", null);
  },
  aggiornaErrore(state, payload) {
    Vue.set(state, "errore", payload);
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
