<template>
  <div class="_container">
    <v-btn
      fixed
      large
      absolute
      fab
      bottom
      right
      class="btnAggiungiPaziente"
      color="primary"
      @click="openPaziente(true, null)"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-col cols="12" class="d-flex justify-center mt-2">
      <v-card
        elevation="0"
        class="d-flex justify-center"
        style="border-radius: 30px; width: 60%"
      >
        <v-text-field
          style="width: 500px"
          class="mb-5"
          placeholder="Cerca paziente..."
          prepend-inner-icon="mdi-magnify"
          v-model.lazy="search"
          clearable
          hide-details
          rounded
          filled
        />
      </v-card>
    </v-col>

    <div style="height: 100%; overflow: hidden">
      <div style="height: 89%; overflow-y: auto; overflow-x: hidden;">
        <v-row class="pa-2 d-flex justify-center align-center" v-if="pazientiVisualizzati.length === 0">
          <v-col cols="6" class="d-flex justify-center _alert">
            <h2>Nessun paziente in sospeso</h2>
          </v-col>
        </v-row>
        <v-row class="pa-2" v-else>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="3"
            v-for="(paziente, index) in pazientiVisualizzati"
            :key="index"
            class="paziente ma-0"
          >
            <v-card elevation="0" outlined style="height: 100%" class="d-flex flex-column">
              <v-card-title class="pa-0 d-flex">
                <v-col cols="6" class="pl-3 pr-0" style="word-break: normal;">
                  {{ paziente.lastName ? paziente.lastName : "" }}
                  {{ paziente.firstName ? paziente.firstName : "" }}
                  {{
                    paziente.lastName == "" || paziente.firstName == ""
                      ? "N.D."
                      : null
                  }}
                </v-col>
                <v-col cols="6" class="d-flex justify-end align-center" style="gap: 1rem">
                  <v-chip color="pending" v-show="paziente.status === 0">
                    <v-icon>mdi-timer-outline</v-icon>
                  </v-chip>
                  <v-chip color="success" v-show="paziente.status === 1">
                    <v-icon>mdi-play-outline</v-icon>
                  </v-chip>
                  <v-btn
                    elevation="0"
                    color="error"
                    @click="openDeleteDialog(paziente.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-card-title>
              <v-divider class="ml-2 mr-2"></v-divider>
              <v-card-text class="pa-3">
                <table>
                  <tr>
                    <td class="campo">Nato/a il</td>
                    <td class="valore">
                      <strong>
                        {{ formattaData(paziente.birth) }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="campo">Intervento</td>
                    <td class="valore">
                      <strong>
                        {{ formattaIntervento(paziente.surgery) }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="campo">Nosologico</td>
                    <td class="valore">
                      <strong>
                        {{ paziente.nosological }}
                      </strong>
                    </td>
                  </tr>
                </table>
              </v-card-text>
              <v-spacer></v-spacer>
              <div class="btn-container">
                <v-btn
                  style="width: 100%"
                  color="primary"
                  :disabled="paziente.status === 1"
                  @click="openPaziente(false, paziente)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                  Modifica anagrafica
                </v-btn>
              </div>
              <div class="btn-container">
                <v-btn
                  style="width: 100%"
                  color="primary"
                  :disabled="!paziente.surgery"
                  @click="openIntervento(paziente)"
                >
                  <v-icon>mdi-play-outline</v-icon>
                  inizia Intervento
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-snackbar v-model="verifyError" color="error" :timeout="3000">
      <h2 style="text-align: center">{{ errorText }}</h2>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent>
      <app-firma
        @click="(event) => deletePaziente(event)"
        :operazione="false"
        :elimina="true"
        :loading="loading"
        :end="deleteDialog"
      ></app-firma>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="300">
      <v-card class="pb-1">
        <v-toolbar color="error" dark flat>
          <v-toolbar-title>Attenzione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          Inserire tutti i dati relativi all'operatore scelto
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="errorDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ControllaOperatoreDialog from "@/components/ControllaOperatoreDialog.vue";
import Firma from "@/components/Firma.vue";

export default {
  components: {
    "app-firma": Firma
  },
  data() {
    return {
      search: "",
      invalidPin: false,
      selezionato: null,
      deleteDialog: false,
      errorDialog: false,
      loading: false,
      errorStorage: false,
      errorText: ""
    };
  },
  computed: {
    ...mapGetters({
      pazienti: "pazienti/pazienti",
      formattaIntervento: "categorie/formattaIntervento"
    }),
    pazientiVisualizzati() {
      let visualizzati = [];
      this.pazienti.filter((paz) => {
        if (paz.status === 0 || paz.status === 1) {
          visualizzati.push(paz);
        }
      });
      if (this.search) {
        return visualizzati.filter((paziente) => {
          return (
            paziente.firstName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            paziente.lastName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            paziente.surgery
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            paziente.nosological
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      }
      return visualizzati;
    },
    verifyError: {
      get() {
        if (this.invalidPin || this.errorStorage) {
          return true;
        } else {
          return false;
        }
      },
      set() {
        return false
      }
    },
  },
  methods: {
    openIntervento(paziente) {
      // console.log("paziente: ", paziente);
      // paziente.accessori.forEach((acc) => {
      //   if (acc.selezionato) {
      //     console.log("c'è n'accessorio selezionato: ", acc);
      //   }
      // });
      this.$router.push({
        path: `interventi/${paziente.surgery}`,
        query: {
          idPaziente: paziente.id
        }
      });
    },
    openPaziente(crea, item) {
      if (crea)
        this.$router.push({
          name: "InterventoPaziente",
          params: { crea: true }
        });
      else
        this.$router.push({
          name: "InterventoPaziente",
          params: { crea: false, item: item, id: item.id }
        });
    },
    openDeleteDialog(id) {
      this.selezionato = id;
      this.deleteDialog = true;
      // console.log("delete: ", this.deleteDialog);
    },
    async deletePaziente(event) {
      if (event.abort === true) {
        this.deleteDialog = false;
        return;
      }
      this.errorStorage = false;
      this.invalidPin = false;
      this.loading = true;
      try {
        console.log("event: ", event);
        await this.$store.dispatch("pazienti/eliminaPaziente", {
          action: "delete",
          id: this.selezionato,
          operatore: event.valutatore,
          pin: event.number,
          payload: event.firma
        });
      } catch (error) {
        if (error.message == "NotAuthorizedError - Pin not valid") {
          this.errorText = "PIN non valido";
          this.invalidPin = true;
        } else {
          this.errorText = "Errore durante l'eliminazione del paziente";
          this.errorStorage = true;
        }
        console.log("error: ", error);
      } finally {
        this.loading = false;
      }
      if (!this.invalidPin && !this.errorStorage) this.deleteDialog = false;
    }
  },
  created() {}
};
</script>

<style>
._header {
  width: 100vw;
  position: fixed !important;
  z-index: 3;
}
.container {
  /* display: flex; */
  height: calc(100vh - 50px);
  /* flex-direction: column; */
  width: 100%;
  margin: 0 !important;
  padding: 0;
}
.btnAggiungiPaziente {
  bottom: 60px !important;
}
.interventi {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.btn-container {
  /* display: flex; */
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px;
  padding-top: 0;
  bottom: 0;
}

/*table*/
.paziente {
  cursor: pointer;
  padding: 10px;
}
/* .operatore .v-card {
  border-radius: 30px;
} */
.paziente table {
  width: 100%;
  border-collapse: collapse;
}

.paziente tr:not(:last-child) {
  border-bottom: 1px solid rgba(62, 62, 62, 0.2);
}

.paziente .campo {
  font-weight: bold;
  padding: 8px 0;
  padding-right: 10px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
}
.paziente .valore {
  padding-left: 10px;
  text-align: right;
  padding: 8px 0;
}
._alert {
  /* width: min-content; */
  display: flex;
  justify-content: center;
  /* border: 5px solid #f44336; */
  border-radius: 15px;
  background-color: #f5a623;
  margin-top: 10px;
}
</style>