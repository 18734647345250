import axios from "axios";
import firebase from "firebase/compat";
import { Filesystem } from "@capacitor/filesystem";
import { storage, database } from "@/plugins/firebase";
import { convertiTimestamp } from "@/functions/utils";
import store from "@/store/index.js";

const DIRECTORY = "DOCUMENTS";

/**
 * Function - log: Void
 * Funzione che aggiorna la descrizione durante la verifica della presenza
 * degli aggiornamenti ed, eventualmente dell'aggiornamento, che viene visualizzata
 * in tempo reale nella vista "Home"
 * @param {String} descrizione
 */
function log(descrizione) {
  store.commit("aggiornamento/aggiornaDescrizione", descrizione);
}

Array.prototype.add = function (element) {
  if (!this.includes(element)) {
    this.push(element);
  }
};

Array.prototype.remove = function (element) {
  const index = this.indexOf(element);
  if (index > -1) {
    this.splice(index, 1);
  }
};

/**
 * Funzione - eliminaModello: Void
 * Funzione che, dato l'id del modello dell'accessorio che si intende eliminare,
 * lo elimina dal filesystem (se esiste)
 * @param {String} id
 */
async function eliminaModello(id) {
  const { files } = await Filesystem.readdir({
    path: "accessori",
    directory: DIRECTORY
  });
  if (files.includes(`${id}.gltf`)) {
    await Filesystem.deleteFile({
      path: `accessori/${id}.gltf`,
      directory: DIRECTORY
    });
  }
}

/**
 * Funzione - aggiungiModello: Void
 * Funzione che, dato l'id del modello dell'accessorio che si intende aggiungere,
 * lo scarica da Firebase Storage e lo salva nel filesystem.
 * @param {String} id
 */
async function aggiungiModello(id) {
  const ref = `accessori/modelli/`;
  const accessorio = await storage
    .ref(ref)
    .child(`${id}.gltf`)
    .getDownloadURL();
  const blobAccessorio = await axios.get(accessorio, { responseType: "blob" });
  const dataAccessorio = await blobToBase(blobAccessorio.data);
  await Filesystem.writeFile({
    path: `acessori/${id}.gltf`,
    data: dataAccessorio,
    directory: DIRECTORY
  });
}

async function blobToBase(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function getConfig(id) {
  const idInterventi = store.getters["struttura/interventi"];
  let idConfig = null;
  idInterventi.forEach((intervento) => {
    if (intervento.idFirebase === id) idConfig = intervento.configurazione;
  });
  return idConfig;
}

export async function aggiornaApplicazione() {
  try {
    store.commit("aggiornamento/aggiornaControllo", true);
    /**
     * Creazione, della directory "accessori" (se non presente).
     * Dette directory serviranno a contenenere i modelli
     * e le immagini degli accessori che verranno utilizzati per creare
     * le configurazioni.
     */
    const { files } = await Filesystem.readdir({
      path: "",
      directory: DIRECTORY
    });
    if (!files.includes("accessori"))
      await Filesystem.mkdir({
        path: "accessori",
        directory: DIRECTORY
      });

    const interventi = [];
    const configurazioni = {};
    const accessori = {};

    const listaAccessoriSalvati = [
      ...store.getters["categorie/listaAccessori"]
    ];
    const ottieniAccessorioSalvato = store.getters["categorie/accessorio"];

    const idInterventi = store.getters["struttura/interventi"];
    const idConfigurazioni = [];
    const idAccessori = [];

    const accessoriDaAggiornare = [];

    log("Caricamento degli interventi...");
    const interventiDivisi = [[]];
    let i = 0;
    idInterventi.forEach((id) => {
      if (interventiDivisi[i].length >= 10) {
        interventiDivisi.push([]);
        i++;
      }
      interventiDivisi[i].push(id.idFirebase);
      idConfigurazioni.add(id.configurazione);
    });
    for (const ids of interventiDivisi) {
      if (ids.length === 0) continue;
      const docs = await database
        .collection("interventi")
        .where(firebase.firestore.FieldPath.documentId(), "in", ids)
        .get();
      docs.forEach((doc) => {
        interventi.push({
          ...doc.data(),
          id: doc.id,
          configurazione: getConfig(doc.id)
        });
      });
    }

    log("Caricamento delle configurazioni...");
    const configurazioniDivise = [[]];
    let c = 0;
    idConfigurazioni.forEach((id) => {
      if (configurazioniDivise[c].length >= 10) {
        configurazioniDivise.push([]);
        c++;
      }
      configurazioniDivise[c].push(id);
    });

    for (const ids of configurazioniDivise) {
      if (ids.length === 0) continue;
      const docs = await database
        .collection("configurazioni")
        .where(firebase.firestore.FieldPath.documentId(), "in", ids)
        .get();
      docs.forEach((doc) => {
        const configurazione = doc.data();
        configurazioni[doc.id] = configurazione;
        configurazione.configurazioneScenaAccessori.forEach(
          ({ originalName }) => {
            idAccessori.add(originalName);
          }
        );
        configurazione.configurazioneScenaPosizionamento.forEach(
          ({ originalName }) => {
            idAccessori.add(originalName);
          }
        );
      });
    }

    log("Caricamento degli accessori...");
    const accessoriDivisi = [[]];
    let a = 0;
    idAccessori.forEach((id) => {
      if (accessoriDivisi[a].length >= 10) {
        accessoriDivisi.push([]);
        a++;
      }
      accessoriDivisi[a].push(id);
    });
    for (const ids of accessoriDivisi) {
      if (ids.length === 0) continue;
      //console.log(ids)
      const docs = await database
        .collection("accessori")
        .where(firebase.firestore.FieldPath.documentId(), "in", ids)
        .get();
      // console.log(docs.docs.length)
      docs.forEach((doc) => {
        const accessorio = doc.data();
        accessori[doc.id] = accessorio;
        const accessorioSalvato = ottieniAccessorioSalvato(doc.id);
        if (accessorioSalvato === undefined) {
          accessoriDaAggiornare.push({ ...accessorio, id: doc.id });
          return;
        }
        const modificaAccessorio = convertiTimestamp(
          accessorio.dataUltimaModifica
        );
        const modificaAccessorioSalvato = convertiTimestamp(
          accessorioSalvato.dataUltimaModifica
        );
        if (modificaAccessorio > modificaAccessorioSalvato) {
          accessoriDaAggiornare.push({ ...accessorio, id: doc.id });
          return;
        }
        listaAccessoriSalvati.remove(doc.id);
      });
    }

    if (listaAccessoriSalvati.length > 0) {
      log("Elimino gli accessori non più utilizzati...");
      for (const id of listaAccessoriSalvati) {
        await eliminaModello(id);
      }
    }
    store.commit("aggiornamento/aggiornaControllo", false);

    if (accessoriDaAggiornare.length > 0) {
      store.commit("aggiornamento/aggiornaAggiornamento", true);
      const STEP = 100 / Number(accessoriDaAggiornare.length);
      let stato = 0;
      for (const accessorio of accessoriDaAggiornare) {
        log(
          `Sto scaricando il nuovo accessorio: ${accessorio.nome.toUpperCase()}`
        );
        await eliminaModello(accessorio.id);
        await aggiungiModello(accessorio.id);
        stato += STEP;
        store.commit("aggiornamento/aggiornaStato", stato);
      }
    }
    store.commit("categorie/aggiornaInterventi", interventi);
    store.commit("categorie/aggiornaConfigurazioni", configurazioni);
    store.commit("categorie/aggiornaAccessori", accessori);

    store.commit("aggiornamento/inizializza");
  } catch (error) {
    store.commit("aggiornamento/inizializza");
    store.commit(
      "aggiornamento/aggiornaErrore",
      error.message ? error.message : error
    );
  }
}
