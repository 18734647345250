<template>
  <v-container fluid class="ma0 pa-0">
      <headerr  class="_header">Bibliografia</headerr>
      <div style="overflow: hidden" class="_container">
      <v-row style="overflow: auto; height: 100%; " class="ma-0 ">
          <v-col v-for="item in lista" :key="item.id" cols="12">
              <v-card elevation="10" class="py-10" @click="openURL(item.URL)"><v-row>
                  <v-col cols="3" class="d-flex align-center justify-center">
                  <v-card-title class="titolo ma-0 pa-0">{{item.id}}</v-card-title>
                  </v-col>
                  <v-col cols="9">
                  <v-card-text  class=" ma-0 pa-0 "><a class="linko" target="_blank" :href="item.URL">{{item.description}}</a></v-card-text>
                  </v-col>
                  </v-row>
              </v-card>
          </v-col>
      </v-row>
      </div>
  </v-container>
</template>

<script>
import lista from "../../json/papiri/bibliografia.json"
export default {
  data() {
    return {
        lista
    }
  },
  methods: {
    openURL(url) {
      window.open(url);
    }
  }
}
</script>

<style scoped>
._header{
  top: 0px;
  
  width: 100vw;
  position: fixed !important;
  z-index: 3;
}
._content{
margin-top: 50px !important;
width: 100%;
}
.titolo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 350%;
    color: cornflowerblue;
}
.desc{
    width: 80%;
}
.linko{
    text-decoration: none;
    color: rgb(54, 54, 54);
}
</style>