import { database } from "../../plugins/firebase";
import { getDefaultStruttura } from "@/store/utils.js";
import firebase from "firebase/compat";

const state = {
  id: null,
  struttura: getDefaultStruttura(),
  disconnetti: null
};

const getters = {
  id(state) {
    return state.id;
  },
  struttura(state) {
    return state.struttura;
  },
  interventi(state) {
    return state.struttura.interventi;
  },
  preferiti(state) {
    return state.struttura.preferiti;
  },
  eulaDialog(state) {
    return !state.struttura.eulaAccettata;
  }
};

const mutations = {
  aggiornaId(state, payload) {
    state.id = payload;
  },
  aggiornaStruttura(state, payload) {
    state.struttura = payload;
  }
};

const actions = {
  recuperaDati: async ({ commit, state }, id) => {
    commit("aggiornaId", id);
    const struttura = await database.collection("strutture").doc(id).get();
    commit("aggiornaStruttura", struttura.data());
    state.disconnetti = database
      .collection("strutture")
      .doc(id)
      .onSnapshot((struttura) => {
        commit("aggiornaStruttura", struttura.data());
      });
    return struttura.data();
  },
  disconnetti: ({ state, commit }) => {
    if (state.disconnetti) {
      state.disconnetti();
      state.disconnetti = null;
    }
    commit("aggiornaId", null);
    commit("aggiornaStruttura", getDefaultStruttura());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
