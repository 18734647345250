<template>
  <v-container
    style="overflow: hidden;"
    fluid
    class="ma-0 pa-0 fill-height background"
  >
    <headerr class="_header">Cenni anatomici</headerr>
    <div class="_container">
      <div class="_col _left_col">
        <v-btn
          color="primary"
          class="bottone"
          @click="mostraStadiL = !mostraStadiL"
        >
          Tegumentario
          <v-icon>
            {{ mostraStadiL ? "mdi-chevron-down" : "mdi-chevron-right" }}
          </v-icon>
        </v-btn>
        <v-row v-if="mostraStadiL" class="mt-4" justify="center">
          <v-col v-for="(stadio, i) in stadi" :key="i" cols="12">
            <v-btn @click="infoStadi(stadio, i)" class="bottone textWrapBtn">
              {{ stadio.title }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div class="_col _right_col">
        <v-btn
          color="primary"
          class="bottone"
          @click="
            mostraStadiR = !mostraStadiR;
            nervosoMostrato = 0;
          "
        >
          Nervoso
          <v-icon>
            {{ mostraStadiR ? "mdi-chevron-down" : "mdi-chevron-right" }}
          </v-icon>
        </v-btn>
        <v-row class="mt-4" v-if="mostraStadiR">
          <!-- TESTA -->
          <v-col cols="12">
            <v-btn
              @click="nervosoMostrato = nervosoMostrato == 1 ? 0 : 1"
              class="bottone"
            >
              Testa
              <v-icon>
                {{
                  nervosoMostrato == 1
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-btn>
            <v-row v-if="nervosoMostrato == 1" class="mt-3" justify="center">
              <v-col
                v-for="item in testa"
                :key="item.id"
                class="centracol"
                cols="12"
              >
                <v-btn @click="infoNervoso(item)" class="sottobottone">
                  <span class="text-wrap testo-bottone">{{ item.name }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- TORACE -->
          <v-col cols="12">
            <v-btn
              @click="nervosoMostrato = nervosoMostrato == 2 ? 0 : 2"
              class="bottone"
            >
              Torace
              <v-icon>
                {{
                  nervosoMostrato == 2
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-btn>
            <v-row v-if="nervosoMostrato == 2" class="mt-3" justify="center">
              <v-col
                v-for="item in toracino"
                :key="item.id"
                class="centracol"
                cols="12"
              >
                <v-btn @click="infoNervoso(item)" class="sottobottone">
                  <span class="text-wrap testo-bottone">{{ item.name }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- BRACCIA -->
          <v-col cols="12">
            <v-btn
              @click="nervosoMostrato = nervosoMostrato == 3 ? 0 : 3"
              class="bottone"
            >
              Braccia
              <v-icon>
                {{
                  nervosoMostrato == 3
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-btn>
            <v-row v-if="nervosoMostrato == 3" class="mt-3" justify="center">
              <v-col
                v-for="item in braccia"
                :key="item.id"
                class="centracol"
                cols="12"
              >
                <v-btn @click="infoNervoso(item)" class="sottobottone">
                  <span class="text-wrap testo-bottone">{{ item.name }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- GAMBE -->
          <v-col cols="12">
            <v-btn
              @click="nervosoMostrato = nervosoMostrato == 4 ? 0 : 4"
              class="bottone"
            >
              Gambe
              <v-icon>
                {{
                  nervosoMostrato == 4
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-btn>
            <v-row v-if="nervosoMostrato == 4" class="mt-3" justify="center">
              <v-col
                v-for="item in gambe"
                :key="item.id"
                class="centracol"
                cols="12"
              >
                <v-btn @click="infoNervoso(item)" class="sottobottone">
                  <span class="text-wrap testo-bottone">{{ item.name }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
<div class="ContainerImmagine">

  <v-img width="100%" class="" height="100%" contain src="/UOMO_pelle.png" />
</div>
    </div>

    <v-dialog v-model="mostraInfoDialog" max-width="800px">
      <v-card height="80vh">
        <v-card-title
          style="height: 55px"
          class="primary bianco d-flex justify-space-between"
        >
          {{ itemMostrato.title }}
          <div class="scivoloC">
            <v-icon color="white" class="my-0 py-0">
              mdi-format-font-size-decrease
            </v-icon>
            <v-slider
              hide-details=""
              color="white"
              class="my-0 py-0"
              dense
              :max="25"
              :min="14"
              v-model="fontCard"
            ></v-slider>
            <v-icon color="white" class="my-0 py-0">
              mdi-format-font-size-increase
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text :style="stile" class="testoDialog">
          <v-img
            v-if="itemMostrato.image"
            contain
            width="100%"
            :src="itemMostrato.image"
          />

          {{ itemMostrato.description }}
        </v-card-text>
        <v-card-actions style="height: 50px" class="">
          <v-spacer />
          <v-btn
            elevation="0"
            color="error"
            text
            @click="mostraInfoDialog = false"
          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import stadi from "../../json/stadi.json";
import braccia from "../../json/nervoso/nervoso-braccia.json";
import gambe from "../../json/nervoso/nervoso-gambe.json";
import testa from "../../json/nervoso/nervoso-testa.json";
import toracino from "../../json/nervoso/nervoso-torace-bacino.json";
export default {
  data() {
    return {
      fontCard: 20,
      stadi,
      braccia,
      gambe,
      testa,
      toracino,
      mostraStadiL: true,
      mostraStadiR: true,
      nervosoMostrato: 0,
      mostraInfoDialog: false,

      itemMostrato: {}
    };
  },
  methods: {
    infoNervoso(item) {
      // console.log(item);
      this.itemMostrato = {
        description: item.description,
        title: item.name
      };
      switch (this.nervosoMostrato) {
        case 1:
          this.itemMostrato.image = "/img/nervoso-testa/" + item.image + ".jpg";
          break;
        case 2:
          this.itemMostrato.image =
            "/img/nervoso-torace-bacino/" + item.image + ".jpg";
          break;
        case 3:
          this.itemMostrato.image =
            "/img/nervoso-braccia/" + item.image + ".jpg";
          break;
        case 4:
          this.itemMostrato.image = "/img/nervoso-gambe/" + item.image + ".jpg";
          break;
        default:
          this.itemMostrato.image = null;
      }

      this.mostraInfoDialog = true;
    },
    infoStadi(item, i) {
      
      this.itemMostrato = {
        description: item.description,
        title: item.title
      };
      switch (i) {
        case "Istadio":
          this.itemMostrato.image = "/img/tegumentario/I_Stadio.jpg";
          break;
        case "IIstadio":
          this.itemMostrato.image = "/img/tegumentario/II_Stadio.jpg";
          break;
        case "IIIstadio":
          this.itemMostrato.image = "/img/tegumentario/III_Stadio.jpg";
          break;
        case "IVstadio":
          this.itemMostrato.image = "/img/tegumentario/IV_Stadio.jpg";
          break;
        case "NonStadiabili":
          this.itemMostrato.image = "/img/tegumentario/Non_Stadiabile.jpg";
          break;
        case "SospettoDannoProfondoDelTessuto":
          this.itemMostrato.image =
            "/img/tegumentario/Sospetto_danno_profondo_del_Tessuto.jpg";
          break;
        default:
          this.itemMostrato.image = null;
      }
      

      this.mostraInfoDialog = true;
    }
  },
  mounted(){
    // console.log(this.toracino)
  },
  computed: {
    stile() {
      const dimensione = "font-size:" + this.fontCard + "px;";

      return dimensione;
    },
   
  }
};
</script>

<style scoped>
._header {
  top: 0px;

  width: 100vw;
  position: fixed !important;
  z-index: 3;
}
._content {
  margin-top: 50px !important;
  width: 100%;
}
._col {
  position: absolute;
  z-index: 1;
  max-height: calc(100% - 30px);
  width: 30vw;
  max-width: 300px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.123);
  scrollbar-width: none;
}
._left_col {
  left: 0;
  border-bottom-right-radius: 20px;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.452);
  direction: rtl;
}
._right_col {
  right: 0;
  border-bottom-left-radius: 20px;
  box-shadow: -3px 5px 10px 0px rgba(0, 0, 0, 0.452);
}
.ombraColonna {
  background-color: rgba(0, 0, 0, 0.178);
}

.bottone {
  width: 100%;
  height: 60px !important;
  /* font-size: 120%; */
}
.testo-bottone {
  width: 50%;
}
.centracol {
  display: flex;
  justify-content: center;
}
.sottobottone {
  width: 70%;
  height: 50px !important;
  font-size: 80%;
}
.ContainerImmagine {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.scivoloC {
  display: flex;
  justify-self: flex-end;
  width: 300px;
}
.testoDialog {
  overflow: auto;
  height: 700px;
  letter-spacing: 1px;
  line-height: 150%;
  height: calc(100% - 105px);
}
</style>
