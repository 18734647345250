<template>
  <v-row align="center" justify="center" class="ma-0 pa-0">
    <!-- <v-col sm="10" lg="4" class="d-flex pa-0"> -->
    <v-card width="100%" outlined>
      <v-row align="center" justify="end" class="ma-2">
        <v-col cols="12">
          <v-btn
            :color="errorValutatore ? 'error' : 'primary'"
            width="100%"
            @click="valutatoriDialog = true"
          >
            <!-- :disabled="dataFirma.firma != null" -->
            <v-icon left v-show="!dataFirma.valutatore">mdi-plus</v-icon>
            {{
              dataFirma.valutatore
                ? formattaValutatore(dataFirma.valutatore)
                : "Inserisci Valutatore"
            }}
          </v-btn>

          <!-- <VueSignaturePad
            id="signature"
            width="100%"
            height="200px"
            ref="signaturePad"
            class="elevation-2 mb-2"
            :options="{
              onBegin: () => {
                $refs.signaturePad.resizeCanvas();
              }
            }"
            :error="$v.dataFirma.firma.$error"
          /> -->
          <!-- v-if="dataFirma.firma == null" -->

          <!-- <v-img
            width="100%"
            height="200px"
            :src="firmaInput"
            class="elevation-2 mb-2"
            v-if="dataFirma.firma != null"
          ></v-img> -->

          <!-- <v-btn color="error" width="100%" @click="cancellaFirma" class="mb-2">
            Cancella firma
          </v-btn> -->
        </v-col>
        <v-col cols="12">
          <v-text-field
            placeholder="PIN"
            label="PIN operatore"
            hide-details
            outlined
            dense
            type="number"
            screen-reader-only
            v-model="$v.dataFirma.number.$model"
            :error="$v.dataFirma.number.$error"
            hide-spin-buttons
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="operazione">
          <v-textarea
            placeholder="Inserisci le tue note"
            auto-grow
            clearable
            class="mb-3"
            label="Note post procedura"
            hide-details
            outlined
            dense
            v-model="notePost"
          ></v-textarea>
        </v-col>

        <v-card-actions v-if="elimina" class="d-flex justify-end" width="100%">
          <v-btn text elevation="0" @click="exitClicked">Annulla</v-btn>
          <v-btn
            color="error"
            elevation="0"
            @click="saveButtonClicked"
            :loading="loading"
          >
            Elimina
          </v-btn>
        </v-card-actions>
        <v-col cols="12" v-else>
          <v-btn
            color="primary"
            width="100%"
            @click="saveButtonClicked"
            :loading="loading"
          >
            Salva
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- </v-col> -->

    <v-dialog v-model="valutatoriDialog" max-width="300">
      <v-card class="pb-1">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Seleziona valutatore</v-toolbar-title>
        </v-toolbar>

        <v-col
          cols="12"
          v-for="(valutatore, index) in listaValutatori"
          :key="index"
          class="pa-2 pt-3"
        >
          <v-btn
            width="100%"
            elevation="4"
            @click="
              dataFirma.valutatore = valutatore.id;
              valutatoriDialog = false;
            "
          >
            {{ formattaValutatore(valutatore.id) }}
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error" max-width="300">
      <v-card>
        <v-toolbar color="error" dark flat>
          <v-toolbar-title>Attenzione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          Inserire tutti i dati relativi all'operatore per procedere con il
          salvataggio
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="error = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { firebaseStore } from "../plugins/firebase";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //   listaValutatori: [],
      valutatoriDialog: false,
      mostraFirma: true,
      notePost: "",
      // listaValutatori: [],
      error: false,
      errorValutatore: false,
      dataFirma: {
        firma: null,
        valutatore: null,
        number: null
      }
    };
  },
  validations: {
    dataFirma: {
      firma: {},
      valutatore: { required },
      number: { required }
    }
  },
  props: {
    valutatoreInput: String,
    firmaInput: String,
    numberInput: String,
    loading: Boolean,
    operazione: Boolean,
    elimina: Boolean,
    closed: Boolean,
    end: Boolean
  },
  methods: {
    cancellaFirma() {
      this.dataFirma.firma = null;

      this.$refs.signaturePad.clearSignature();
    },
    clear() {
      this.dataFirma.valutatore = null;
      this.dataFirma.firma = null;
      this.dataFirma.number = null;
      this.errorValutatore = false;
      this.$v.dataFirma.$reset();
      this.notePost = "";
      this.cancellaFirma();
    },
    exitClicked() {
      this.clear();
      if (this.elimina) this.$emit("click", { abort: true });
    },
    saveButtonClicked() {
      const { $touch, $invalid } = this.$v.dataFirma;
      if (this.firmaInput == this.dataFirma.firma && this.firmaInput != null) {
        this.$emit("check", (result) => {
          if (result) {
            $touch();
            if (!$invalid) {
              const parms = {
                firmaVuota: false,
                firma: this.dataFirma.firma,
                valutatore: this.valutatoreInput,
                number: this.dataFirma.number,
                postNote: this.notePost
              };

              this.$emit("save", parms);
            } else {
              if (this.dataFirma.valutatore === null) {
                this.errorValutatore = true;
              }
              this.error = true;
            }
          }
        });
        return;
      }
      if (this.elimina || this.operazione) {
        this.checkFirmaData();
      } else {
        this.$emit("check", (result) => {
          if (result) {
            this.checkFirmaData();
          }
        });
      }
      // console.log(parms);
    },
    checkFirmaData() {
      const { $touch, $invalid } = this.$v.dataFirma;
      // const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // this.dataFirma.firma = data;
      // console.log("result ok");
      // console.log("data firma: ", this.dataFirma);
      $touch();
      // &&
      //   this.dataFirma.firma !== undefined &&
      //   this.dataFirma.firma !== null
      if (!$invalid) {
        const parms = {
          // firmaVuota: isEmpty,
          // firma: data,
          valutatore: this.dataFirma.valutatore,
          number: this.dataFirma.number,
          postNote: this.notePost
        };
        console.log("not invalid firma: ", parms);
        if (this.elimina || this.operazione) {
          this.$emit("click", {
            ...parms,
            abort: false
          });
        } else {
          this.$emit("save", parms);
        }
      } else {
        if (this.dataFirma.valutatore === null) {
          this.errorValutatore = true;
        }
        this.error = true;
      }
    },
    formattaValutatore(id) {
      const valutatore = this.listaValutatori.find(
        (valutatore) => valutatore.id == id
      );

      if (valutatore == null || valutatore == undefined) {
        return "Valutatore non trovato";
      }

      return `${valutatore.nome} ${valutatore.cognome}`;
    }
  },
  computed: {
    ...mapGetters({
      listaValutatori: "operatori/operatori"
    }),
    // use() {
    //   if (
    //     !this.number ||
    //     !this.valutatoreSelezionato ||
    //     this.$refs.signaturePad.saveSignature().isEmpty
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

  },

  watch: {
    closed() {
      this.exitClicked();
    },
    end(oldValue, newValue) {
      if (oldValue === false && newValue === true && this.elimina) this.clear();
    },
    "dataFirma.valutatore"() {
      if (this.dataFirma.valutatore !== null) this.errorValutatore = false;
    }
  },

  async created() {
    if (this.valutatoreInput) this.dataFirma.valutatore = this.valutatoreInput;

    // if (this.firmaInput) {
    //   this.dataFirma.firma = this.firmaInput;
    //   this.mostraFirma = false;
    // }

    if (this.numberInput) this.dataFirma.number = this.numberInput;

    // const listaValutatoriRef = await firebaseStore
    //   .collection("operatori")
      
    //   .where("eliminato", "==", false)
    //   .get();
    // listaValutatoriRef.forEach((element) => {
    //   this.listaValutatori.push({ ...element.data(), id: element.id });
    // });
  }
};
</script>