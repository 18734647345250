import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import "./registerServiceWorker";
import mixin from "./plugins/mixin";


import VueSignature from 'vue-signature-pad';
Vue.use(VueSignature);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);
import header from "@/components/Header.vue";

Vue.component("headerr", header);
Vue.config.productionTip = false;
Vue.mixin(mixin);
new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
