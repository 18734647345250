import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:        "#2a59a4",
                secondary:      "#4983e7",
                tertiary:       "#e6e6e6",
                grigio:         "#9c9ea1",
                hillrom:        "#3f73b8",
                background:     "#4983e7",
                pending:        "#f5a623",
            }
        }
    }
});
