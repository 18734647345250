import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";


  

import Pazienti from "../views/pazienti/PazientiInSospeso.vue"
import Interventi from "../views/Interventi.vue"

import Formazione from "../views/Formazione.vue"
import Raccomandazioni from "../views/informazioni/Raccomandazioni.vue"
import Bibliografia from "../views/informazioni/Bibliografia.vue"
import CenniAnatomici from "../views/informazioni/CenniAnatomici.vue"
import LineeGuida from "../views/informazioni/LineeGuida.vue"
import Procedura from "../views/informazioni/Procedura.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "accedi",
    component: () => import("@/views/Accedi.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/formazione",
    name: "Formazione",
    meta: { requiresAuth: true },
    component: Formazione
  },
  {
    path: "/interventi",
    meta: { requiresAuth: true },
    component: () => import("@/views/Interventi.vue"),
    children: [
      {
        path: "",
        name: "interventi",
        component: () => import("@/views/interventi/Interventi.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: ":idIntervento",
        meta: { requiresAuth: true },
        component: () => import("@/views/interventi/Intervento.vue")
      }
    ]
  },
  {
    path: "/pazienti",
    meta: { requiresAuth: true },
    component: () => import("@/views/pazienti/index.vue"),
    children: [
      {
        path: "",
        name: "pazienti",
        meta: { requiresAuth: true },
        component: () => import("@/views/pazienti/PazientiInSospeso.vue"),
      },
      {
        path: "/pazienti/:id",
        name: "paziente",
        meta: { requiresAuth: true },
        component: () => import("@/views/pazienti/InterventoPaziente.vue")
      }
    ]
  },
  {
    path: "/pazienti-in-sospeso",
    name: "Pazienti",
    meta: { requiresAuth: true },
    component: Pazienti
  },
  {
    path: "/intervento-paziente",
    name: "InterventoPaziente",
    component: () => import("@/views/pazienti/InterventoPaziente.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/raccomandazioni",
    name: "Raccomandazioni",
    meta: { requiresAuth: true },
    component: Raccomandazioni
  },
  {
    path: "/bibliografia",
    name: "Bibliografia",
    meta: { requiresAuth: true },
    component: Bibliografia
  },
  {
    path: "/procedura",
    name: "Procedura",
    meta: { requiresAuth: true },
    component: Procedura
  },
  {
    path: "/lineeGuida",
    name: "Linee guida",
    meta: { requiresAuth: true },
    component: LineeGuida
  },
  {
    path: "/cenniAnatomici",
    name: "Cenni anatomici",
    meta: { requiresAuth: true },
    component: CenniAnatomici
  },
  {
    path: "/operatori",
    meta: { requiresAuth: true },
    component: () => import("@/views/Operatori/index.vue"),
    children: [
      {
        path: "",
        name: "Operatori",
        component: () => import("@/views/Operatori/Operatori.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/operatori/:id",
        name: "Operatore",
        component: () => import("@/views/Operatori/Operatore.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/prova",
    name: "Prova",
    meta: { requiresAuth: true },
    component: () => import("@/views/prova.vue")
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (store.getters["struttura/id"] === null && to.meta.requiresAuth) {
    next({ name: "accedi" });
    return;
  }

  next();
});

export default router;
