<template>
  <v-app-bar max-height="50px" height="50px" color="primary" dark style="position: sticky; top: 0; z-index: 1">
    <v-row align="center">
      <v-col cols="3">
        <v-btn class="my-0 py-5" @click="back()" elevation="0" color="primary">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-flex class="d-flex justify-center">
          <v-toolbar-title
            @click="test()"
            @dblclick="$emit('2click')"
            v-if="!nascondiScritta"
            class="py-1 px-3"
          >
            <slot />
          </v-toolbar-title>
        </v-flex>
      </v-col>
      <v-col cols="3" class="destra">
        <v-btn
          class="my-0 py-5"
          v-if="menu"
          @click="$emit('destra')"
          elevation="0"
          color="primary"
        >
          Cambia tipologia
        </v-btn>
        <v-btn
          color="#FFF"
          elevation="0"
          :style="`color: ${$vuetify.theme.themes.light.primary}`"
          v-if="switchInterventi"
          @click="cambio"
          small
        >
          {{ intervento ? "Vai a Gest. Acc." : "Vai a Pos. Paz." }}
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  // props: ["customBack", "nascondiScritta", "menu", "switchInterventi"],
  props: {
    customBack: {
      type: Boolean,
      default: false
    },
    nascondiScritta: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Boolean,
      default: false
    },
    switchInterventi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      intervento: true
    };
  },
  methods: {
    cambio() {
      this.intervento = !this.intervento;
      this.$emit("interventoCambiato", this.intervento);
    },
    back() {
      if (this.customBack) this.$emit("back");
      else this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.destra {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bottoneInterventi {
  text-transform: none !important;
}
</style>
