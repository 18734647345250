export default {
  methods: {
    log(...args) {
      console.log(...args);
    },
    formattaData(date) {
      if (!date) return "";
      if (date.includes("/")) return date;
      const dateArray = date.split("-");
      return [dateArray[2], dateArray[1], dateArray[0]].join("/");
    },
    formattaSesso(sesso) {
      if (!sesso) return "";
      return this.sessi.find((s) => s.value === sesso).text;
    }
  },
  data() {
    return {
      sessi: [
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
        { value: "O", text: "Altro" }
      ]
    };
  }
};
