import { firebaseStore } from "@/plugins/firebase";

const state = {
  liste: null,
  gender: [
    { value: "M", text: "Maschio" },
    { value: "F", text: "Femmina" },
    { value: "O", text: "Altro" }
  ],
  status: [
    { value: 0, desc: "Imported" },
    { value: 1, desc: "Started" },
    { value: 2, desc: "Completed" },
    { value: 3, desc: "Cancelled" }
  ],
  valutatori: [
    { pin: 12345, nome: "Valutatore 1" },
    { pin: 54321, nome: "Valutatore 2" },
  ],
};
const getters = {
  specialità: (state) => {
    return [...state.liste.specialità];
  },
  gender: (state) => {
    return [...state.gender];
  },
  valutatori: (state) => {
    return [...state.valutatori];
  },
  status: (state) => {
    return [...state.status];
  }
};
const mutations = {
  aggiornaListe(state, payload) {
    state.liste = payload;
  }
};
const actions = {
  recuperaDati: async ({ commit }) => {
    const liste = await firebaseStore.collection("globale").doc("liste").get();
    commit("aggiornaListe", liste.data());
  },
  disconnetti: ({ commit }) => {
    commit("aggiornaListe", null);
  },
  formattaStato: (state, payload) => {
    return state.status.find((item) => item.value === payload).desc;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
