<template>
  <v-app>
    <v-main class="primary">
      <router-view class="white"></router-view>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";
import { StatusBar } from "@capacitor/status-bar";

import AppFooter from "./components/Footer.vue";
export default {
  components: {
    AppFooter
  },
  created() {
    const platform = Capacitor.getPlatform();
    if (platform === "web") {
      return;
    }
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      }
      this.$router.go(-1);
    });
    StatusBar.hide();
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.bianco {
  color: white;
}
.sotto {
  margin-bottom: 50px;
}
.sopra {
  padding-top: 50px !important;
}
._container {
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  margin-top: 50px;
  width: 100%;
  position: relative;
}
.noTextTransform {
  text-transform: none;
}
.textWrapBtn span {
  white-space: normal !important;
  word-wrap: break-word !important;
  max-width: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
  display: inline-block;
}

.maino {
  height: max(100vh - 50px);
}
#app {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  font-family: ITC;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("fonts/HelveticaNeueLTStd\ Lt.otf") format("opentype");
}

@font-face {
  font-family: ITC;
  src: url("fonts/ITCAvantGardeStdBk.otf") format("opentype");
}

@font-face {
  font-family: Texgyreadventor;
  src: url("fonts/texgyreadventor-regular.otf") format("opentype");
}
</style>
